<template>
<div class="container padding-container">
    <el-button class="topBtns" type="primary" icon="el-icon-plus" @click="routerChange(1)">新增兑换码</el-button>
    <div class="border-container">
        <el-row class="search-container" type="flex" align="middle" justify="space-between">
            <el-col :lg="3" :sm="4" :xs="8">
                <span class="list-title">兑换码列表</span>
            </el-col>
            <el-col :lg="21" :sm="20" :xs="16" class="search-area">
                <div class="search-item">
                    <el-date-picker v-model="searchForm.time" type="daterange" clearable value-format='timestamp'
                        range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" @change="pageChange(1)">
                    </el-date-picker>
                </div>
            </el-col>
        </el-row>

        <div class="table-container">
            <el-table :data="tableData" tooltip-effect="dark" style="width: 100%" size="medium"
                :header-cell-style="{height: '40px', background: 'linear-gradient(180deg,rgba(251,252,253,1) 0%,rgba(246,248,250,1) 100%)'}"
                :cell-style="{height: '50px',padding: '5px 0'}" header-align="center">
                <el-table-column label="兑换码名称" min-width="150" :show-overflow-tooltip="true">
                    <template slot-scope="scope">
                        <div class="hover-text-colourful fz-bold" @click="routerChange(3, scope.row)"
                            style="padding-left: 5px">
                            {{scope.row.name}}
                        </div>
                    </template>
                </el-table-column>

                <el-table-column prop="count" label="兑换总量" min-width="120" :show-overflow-tooltip="true">
                </el-table-column>
                <el-table-column prop="user_per_count" label="每人可用" min-width="120" :show-overflow-tooltip="true">
                    <template slot-scope="scope">
                        {{scope.row.user_per_count || '不限制'}}
                    </template>
                </el-table-column>
                <el-table-column label="有效期" min-width="190" align="center">
                    <template slot-scope="scope">
                        {{scope.row.begin_time | timeFilter(6)}} - {{scope.row.end_time | timeFilter(6)}}
                    </template>
                </el-table-column>
                <el-table-column label="操作" min-width="130" align="center">
                    <template slot-scope="scope">
                        <el-button type="text" class="icon-btn" @click="routerChange(3, scope.row)">
                            <el-tooltip placement="top" content="详情">
                                <i class="iconfont icon-check" />
                            </el-tooltip>
                        </el-button>
                        <el-button type="text" class="icon-btn" @click="routerChange(2, scope.row)">
                            <el-tooltip placement="top" content="编辑">
                                <i class="iconfont icon-edit" />
                            </el-tooltip>
                        </el-button>
                        <el-button type="text" class="icon-btn" @click="handleDelete(scope.row.id)">
                            <el-tooltip placement="top" content="删除">
                                <i class="iconfont icon-delete" />
                            </el-tooltip>
                        </el-button>
                    </template>
                </el-table-column>
                <div class="" slot="empty">
                    <no-data></no-data>
                </div>
            </el-table>
        </div>
        <div class="page-container">
            <el-pagination layout="total, sizes, prev, pager, next" background @size-change="pageSizeChange"
                @current-change="pageChange" :current-page="currPage" :total="total" :page-size="pageSize">
            </el-pagination>
        </div>
    </div>
</div>
</template>
<script>
import Core from '@/core';
const USER_TYPE = Core.Const.USER_TYPE;
export default {
    components: {
        NoData: () => import('@/components/Empty.vue')
    },
    props: {},
    data() {
        return {
            userType: Core.Data.getUserType(),
            USER_TYPE: Core.Const.USER_TYPE,

            total: 0,
            currPage: 1,
            pageSize: 10,

            searchForm: {
                status: '',
                time: ''
            },
            statusList: Core.Const.COURSE.CONVERT_STATUS,

            tableData: []
        };
    },
    watch: {},
    computed: {},
    mounted() {},
    created() {},
    mounted() {
        this.getTableData();
    },
    methods: {
        auth: Core.Utils.auth,
        pageChange(page) {
            this.currPage = page;
            this.getTableData();
        },
        pageSizeChange(size) {
            this.pageSize = size;
            this.getTableData();
        },
        getTableData() {
            Core.Api.ExChangeCode.list(
                this.searchForm.status,
                this.searchForm.time[0] / 1000 || '',
                this.searchForm.time[1] / 1000 || '',
                this.currPage,
                this.pageSize
            ).then((res) => {
                console.log('getTableData res', res);
                this.total = res.count;
                this.tableData = res.list;
            });
        },
        routerChange(key, item) {
            switch (key) {
                case 1:
                    this.$router.push('/online-marketing/convert-edit');
                    break;
                case 2:
                    this.$router.push('/online-marketing/convert-edit?id=' + item.id);
                    break;
                case 3:
                    this.$router.push('/online-marketing/convert-detail?id=' + item.id);
                    break;
            }
        },
        handleDelete(id) {
            this.$confirm('确定执行删除操作?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                Core.Api.ExChangeCode.delete(id).then((res) => {
                    this.$message.success('删除成功!');
                    this.getTableData();
                });
            });
        }
    }
};
</script>

<style lang="scss" scoped>
.container {
    @import '@/common/styles/table.scss';
}
</style>